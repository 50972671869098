import { CategoryItem, Modifier, ModifierItem } from "src/entities/Category/Category"
import { CookingInfo } from "src/entities/Order/Info/CookingInfo"
import { Info } from "src/entities/Order/Info/Info"
import { OrderTypeInfo } from "src/entities/Order/Info/OrderTypeInfo"
import { References } from "src/entities/Order/Info/References"
import { DeliveryAddress } from "src/entities/Order/Info/Status/Delivery/DeliveryAddress"
import { DeliveryAreaInfo } from "src/entities/Order/Info/Status/Delivery/DeliveryAreaInfo"
import { DeliveryInfo } from "src/entities/Order/Info/Status/Delivery/DeliveryInfo"
import { Status } from "src/entities/Order/Info/Status/Status"
import { Order } from "src/entities/Order/Order"
import { OrderTypes } from "src/entities/Order/OrderTypes"
import { Item } from "src/entities/Order/SubOrder/Item"
import { SubOrder } from "src/entities/Order/SubOrder/SubOrder"
import { HashAllOrderItem } from "src/utility/Helper"
import { v4 as guid } from 'uuid'
import _ from "lodash"
import { OrderEvent } from "src/entities/Order/OrderEvent"
import { getOldOrdersForUser } from "src/services/orders"
import { OrderGrouped } from "src/entities/Order/OrderGrouped"
import { getOrdersLocally } from "src/localStorage/orderStorage"
import { OrderStatus } from "src/entities/Order/OrderStatus"

export function newOrder(): Order {

    const orderTypeInfo: OrderTypeInfo = {
        id: OrderTypes.dineIn,
        name: ""
    }

    const cookingInfo: CookingInfo = {
        chefId: null,
        hasCookingItems: false
    }

    const deliveryAreaInfo: DeliveryAreaInfo = {
        areaName: null,
        cost: null,
        id: null,
        standardServiceTime: null
    }

    const deliveryInfo: DeliveryInfo = {
        courierId: null,
        deliveryStatus: null,
        deliveryStatusId: null,
        deliveryTime: null,
        deliveryAreaInfo
    }

    const status: Status = {
        isPaid: false,
        paymentTypeId: 0,
        deliveryInfo,
        cookingInfo,
        status: OrderStatus.New
    }

    const references: References = {
        id: guid(),
        orderNo: null
    }

    const info: Info = {
        creationTime: new Date(),
        employeeId: 0,
        channel: process.env.REACT_APP_POS_CHANNEL ? process.env.REACT_APP_POS_CHANNEL : 'system',
        tableId: null,
        references,
        orderTypeInfo,
        customerId: null,
        address: null,
        status,
        isProcessed: false
    }

    const order: Order = {
        info,
        subOrders: []
    }

    return order
}

export const getActiveSubOrder = (orderData: Order): SubOrder => {
    const activeSubOrder = orderData.subOrders.find(subOrder => subOrder.isPrinted === false)

    if (activeSubOrder !== undefined) return activeSubOrder
    else {
        const subOrder: SubOrder = {
            orderNo: 0,
            isPrinted: false,
            isForKitchen: false,
            isCooked: false,
            isLocked: false,
            lockedBy: 0,
            comment: null,
            createdDate: new Date(),
            items: [],
            id: guid()
        }

        orderData.subOrders.push(subOrder)
        return orderData.subOrders[0]
    }
}

export const getPrice = (categoryItem: CategoryItem): number => {
    let price = 0
    categoryItem.modifiers.forEach((modifier) => {
        modifier.modifierItems.forEach((item) => {
            price += item.price * (item.quantity ? item.quantity : 0)
        })
    })

    price += categoryItem.price
    return price
}

export const printSubOrder = async (orderData: Order) => {
    const activeSubOrder = _.filter(orderData.subOrders, function (subOrder) {
        return subOrder.isPrinted === false
    })

    if (activeSubOrder.length > 0) {
        activeSubOrder[0].isPrinted = true
        activeSubOrder[0].event = OrderEvent.Modified
    }

    console.log(JSON.stringify(orderData))

    // await updateOrder(orderData)
}

export const getGroupedByDateOrders = async (employeeId: number): Promise<OrderGrouped[]> => {
    //localforage.config(dbConfig)
    let ordersFromBackend: Order[] = []
    let result: OrderGrouped[] = []

    // ordersFromBackend = await getOldOrdersForUser(employeeId)
    const ordersFromIndexedDb = await getOrdersLocally()

    if (ordersFromIndexedDb) {
        //marrim listen e orders qe jane ne backend por jo ne IndexedDb
        const filteredList: Order[] = ordersFromBackend.filter(obj1 => !ordersFromIndexedDb.some(obj2 => obj2.info.references.id === obj1.info.references.id))

        //i shtojme ne listen totale
        ordersFromBackend = [...ordersFromBackend, ...filteredList]

        //grupojme rezultatin sipas dates se krijimit
        result = _.chain(ordersFromIndexedDb)
            .groupBy(x => new Date(x.info.creationTime).toLocaleDateString())
            .map((value, key) => ({ days: key, orders: value }))
            .value()

        result.reverse()
    }

    return result
}

export async function setPaymentOption(orderData: Order, paymentId: string): Promise<Order> {
    // _.filter(orderData.subOrders, function(subOrder) {
    //     if (!subOrder.isPrinted) {
    //         subOrder.paymentTypeId = paymentId
    //         subOrder.event = OrderEvent.Modified
    //     }
    //  })

    // orderData.info.status.paymentTypeId = paymentId

    // await updateOrderLocally(orderData)
    return orderData
}

export const getItemTotalAmount = (item: Item): number => {
    let amount = 0

    if (!item) return 0

    // if (item.event === OrderEvent.Remove) return 0

    // if (item.modifiers) item.modifiers.forEach((modifier) => {
    //     if (modifier.items) {
    //         modifier.items.forEach((modifierItem) => {
    //             if (modifierItem.event !== OrderEvent.Remove) amount += modifierItem.price
    //         })
    //     }
    // })

    amount += item.price

    return amount
}

export const orderTotalAmount = (order: Order): number => {
    let total = 0
    for (const subOrder of order.subOrders) {
        const subTotal = _.sumBy(subOrder.items, function (item: Item) {
            return getItemTotalAmount(item)
        })

        total += subTotal
    }

    return total
}


export const activeSubOrderTotalAmount = (orderData: Order): number => {
    const subOrder = getActiveSubOrder(orderData)
    if (!subOrder) return 0

    let amount = 0
    subOrder.items.forEach(item => {
        amount += getPrice(item) * (item.quantity ? item.quantity : 0)
    })

    return amount
}

export const setOrderCommentOperation = (orderInfo: Order, comment: string) => {

    if (!orderInfo) return

    const activeSubOrder = getActiveSubOrder(orderInfo)

    if (!activeSubOrder) return

    // Update the comment of the active sub-order
    activeSubOrder.comment = comment

    // Create a new order with updated sub-orders
    const updatedOrder: Order = {
        info: orderInfo.info,
        subOrders: orderInfo.subOrders.map(subOrder => (subOrder.id === activeSubOrder.id ? activeSubOrder : subOrder))
    }

    return { ...updatedOrder }
}

export const setOrderTypeOperation = (order: Order, orderType: number) => {
    order.info.orderTypeInfo.id = orderType
    if (orderType !== OrderTypes.dineIn) order.info.tableId = null
    return order
}

export const setOrderStatusOperation = (order: Order, status: string) => {
    order.info.status.status = status
    if (status === OrderStatus.Finalized) order.info.status.isPaid = true
    return { ...order }
}

export const applyCustomerOperation = (order: Order, customerId: string | null) => {
    order.info.customerId = customerId
    if (!customerId) order.info.address = null
    return { ...order }
}

export const applyAddressOperation = (order: Order, deliveryAddress: DeliveryAddress) => {
    order.info.address = deliveryAddress
    return { ...order }
}

export const applyCourierOperation = (order: Order, courierId: number) => {
    order.info.status.deliveryInfo.courierId = courierId
    return { ...order }
}

export const addModifierOperation = (order: Order, menuItem: CategoryItem | undefined, orderItem: CategoryItem | undefined, modifiers: Modifier[], isInsert: boolean): Order => {
    // Deep copy the payload items
    orderItem = orderItem ? { ...orderItem } : undefined
    menuItem = menuItem ? { ...menuItem } : undefined
    modifiers = [...modifiers]

    // Initialize the order if it doesn't exist
    if (!order) order = newOrder()
    const subOrder = getActiveSubOrder(order)

    // Prepare a new order item if not provided
    if (!orderItem && menuItem) {
        orderItem = { ...menuItem }
        const maxSortPriority = Math.max(0, ...subOrder.items.map((item) => item.sortPriority ?? 0))
        orderItem.sortPriority = maxSortPriority + 1
        orderItem.quantity = 1
    }

    if (!orderItem) return { ...order }

    if (isInsert) {
        orderItem.modifiers = [...modifiers]
        orderItem.id = guid()
        const itemHash = HashAllOrderItem(orderItem)
        const existingItemIndex = subOrder.items.findIndex((x) => HashAllOrderItem(x) === itemHash)

        // Handle insertion logic
        if (existingItemIndex !== -1) {
            subOrder.items[existingItemIndex].quantity! += orderItem.quantity ?? 1
        } else subOrder.items.push(orderItem)
    } else {
        const itemHash = HashAllOrderItem(orderItem)
        const existingItemIndex = subOrder.items.findIndex((x) => HashAllOrderItem(x) === itemHash)
        orderItem.modifiers = [...modifiers]
        // orderItem.id = guid()

        // Handle update logic
        if (existingItemIndex !== -1) {
            subOrder.items.splice(existingItemIndex, 1)
        }
        subOrder.items.push(orderItem)
    }

    order.subOrders = order.subOrders.map((sub) => (sub.id === subOrder.id ? subOrder : sub))
    return { ...order }
}

export const removeOrderItemOperation = (order: Order, categoryItem: CategoryItem) => {
    const activeSubOrder = { ...getActiveSubOrder(order) }

    // Find the item in the active sub-order
    const itemIndex = activeSubOrder.items.findIndex((x) => HashAllOrderItem(x) === HashAllOrderItem(categoryItem))

    if (itemIndex !== -1) {
        // Update the quantity and remove the item if necessary
        const itemFound = activeSubOrder.items[itemIndex]
        itemFound.quantity = (itemFound.quantity || 0) - categoryItem.quantity!

        if (itemFound.quantity > 0) {
            activeSubOrder.items[itemIndex] = itemFound  // Update the item in place
        } else {
            activeSubOrder.items.splice(itemIndex, 1) // Remove the item if quantity is 0 or less
        }
    }

    // Update the order with the modified active sub-order
    const data: Order = {
        ...order,
        subOrders: order.subOrders.map((subOrder) => (subOrder.id === activeSubOrder.id ? activeSubOrder : subOrder))
    }

    return data

}

export const addOrderItemOperation = (order: Order, categoryItem: CategoryItem) => {
    const activeSubOrder = getActiveSubOrder(order)

    // Check if the item exists in the active sub-order
    const itemIndex = activeSubOrder.items.findIndex(
        (x) => HashAllOrderItem(x) === HashAllOrderItem(categoryItem)
    )

    if (itemIndex !== -1) {
        // Update the quantity if the item is found
        const itemFound = activeSubOrder.items[itemIndex]
        itemFound.quantity = (itemFound.quantity || 0) + categoryItem.quantity!
    } else {
        // Add a new item with the updated sort priority
        const maxSortPriority = Math.max(
            0, ...activeSubOrder.items.map((x) => x.sortPriority ?? 0)
        )
        categoryItem.id = guid()
        categoryItem.sortPriority = maxSortPriority + 1
        activeSubOrder.items.push({ ...categoryItem })
    }

    // Update the order with the modified active sub-order
    const data: Order = {
        ...order, subOrders: order.subOrders.map((subOrder) => (subOrder.id === activeSubOrder.id ? activeSubOrder : subOrder))
    }

    return data
}