import { CategoryItem, Modifier } from "src/entities/Category/Category"
import _ from "lodash"

const initModifiers: Modifier[] = []
const modifierReducer = (state: Modifier[] = initModifiers, action) => {

    switch (action.type) {
        // case "MODIFIERS_GET":
        //     {
        //         const { order, item }: { order: Order, item: CategoryItem } = action.payload
        //         const activeSubOrder: SubOrder = getActiveSubOrder(order)
        //         const itemFound = activeSubOrder.items.find(x => x.itemId === item.itemId)
        //         if (itemFound) return [...itemFound.modifiers]
        //         else return []

        //     }
        // case "MODIFIER_ADD":
        //     {
        //         let { modifier, modifierItem, orderItem }: { modifier: Modifier, modifierItem: ModifierItem, orderItem: CategoryItem | undefined } = action.payload

        //         modifier = JSON.parse(JSON.stringify(modifier))
        //         modifierItem = JSON.parse(JSON.stringify(modifierItem))
        //         orderItem = orderItem && JSON.parse(JSON.stringify(orderItem))

        //         const modifiers: Modifier[] = state
        //         let modifierItemFound: ModifierItem | undefined

        //         //kerkojme fillimisht per modifier ne state, pra nese ka ndonje item nga ky modifier te zgjedhur me pare
        //         let modifierFound = modifiers.find(x => x.id === modifier.id)

        //         if (modifierFound) {
        //             modifierItemFound = modifierFound.modifierItems.find(x => x.id === modifierItem.id)
        //             if (!modifierItemFound) modifierItemFound = modifier.modifierItems.find(x => x.id === modifierItem.id)
        //         } else {
        //             modifierItemFound = modifier.modifierItems.find(x => x.id === modifierItem.id)
        //             modifierFound = modifier
        //         }

        //         if (modifierItemFound) {
        //             if (modifier.isSingleOption) {
        //                 modifierItem.quantity = 1
        //                 // modifierItem.type = orderItem ? orderItem.itemTypeId : null
        //                 modifierItem.sortPriority = modifierFound!.modifierItems.length === 0 ? nextModifierItemSortPriority(orderItem, modifiers) : modifierFound!.modifierItems[0].sortPriority

        //                 modifierFound!.modifierItems = []
        //                 modifierFound!.modifierItems = [modifierItem]
        //             } else {
        //                 modifierItemFound.quantity++
        //                 if (modifierItemFound.quantity === 1) modifierItemFound.sortPriority = nextModifierItemSortPriority(orderItem, modifiers)
        //                 modifierFound!.modifierItems = [...modifierFound!.modifierItems.filter(x => x.id !== modifierItemFound?.id && x.quantity > 0), modifierItemFound!]
        //             }
        //         }
        //         modifierFound.sortPriority = nextModifierSortPriority(orderItem, modifiers)
        //         return [...modifiers.filter(x => x.id !== modifier.id), modifierFound!]
        //     }

        // case "MODIFIER_REMOVE":
        //     {
        //         let { modifier, modifierItem }: { modifier: Modifier, modifierItem: ModifierItem } = action.payload
        //         modifier = JSON.parse(JSON.stringify(modifier))
        //         modifierItem = JSON.parse(JSON.stringify(modifierItem))
        //         const modifiers: Modifier[] = state

        //         const modifierFound = modifiers.find(x => x.id === modifier.id)
        //         if (modifierFound) {
        //             const modifierItemFound = modifierFound.modifierItems.find(x => x.id === modifierItem.id)
        //             if (modifierItemFound) {
        //                 if (modifierItemFound.quantity === 1) modifierFound.modifierItems = [...modifierFound.modifierItems.filter(x => x.id !== modifierItemFound.id)]
        //                 else modifierItemFound.quantity--
        //             }

        //             return [...modifiers.filter(x => x.id !== modifierFound.id), { ...modifierFound }]
        //         }

        //         return [...modifiers]
        //     }

        case "MODIFIER_SET":
            {
                let orderItem: CategoryItem = action.payload
                orderItem = JSON.parse(JSON.stringify(orderItem))

                return orderItem ? [...orderItem.modifiers] : []
            }

        // case "RESET_MODIFIERS":
        //     return []

        default:
            return initModifiers
    }
}

export default modifierReducer