const isPreloadedFinished: Boolean = false
const preloadReducer = (state: Boolean = isPreloadedFinished, action) => {

    switch (action.type) {
        case "PRELOAD_FINISHED":
            return true
        default:
            return state
    }
}

export default preloadReducer