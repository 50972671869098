import { Area } from "src/entities/Area/Area"
import { getAreas } from "../services/area"
import _ from "lodash"
import { TableStatus } from "src/entities/Table/Table"
import { saveAreaLocally } from "src/localStorage/areaStorage"

const areaReducer = async (state: Area[] = [], action) => {

    switch (action.type) {
        case "INIT_TABLES":
            return [...await getAreas()]

        case "SET_TABLE_STATUS":
            const tableStatus: TableStatus = action.payload.tableStatus
            const tableId: number = action.payload.tableId

            let areas: Area[] = [...await state]

            const areaFound = areas.find(x => x.tables.find(y => y.id === tableId))

            if (areaFound) {
                const tableFound = areaFound.tables.find(x => x.id === tableId)
                if (tableFound) {
                    tableFound.statusId = tableStatus
                    areaFound.tables = [...areaFound.tables.filter(x => x.id !== tableId), tableFound]

                    areas = [...areas.filter(x => x.id !== areaFound.id), areaFound]
                    // await updateAreas(areas)
                    await saveAreaLocally(areaFound[0])
                }
            }

            return areas

        default:
            return state
    }
}

export default areaReducer