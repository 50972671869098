export class OrderStatus {
    static New: string = "New"
    static Accepted: string = "Accepted"
    static Cooking: string = "Cooking"
    static Cooked: string = "Cooked"
    static Delivering: string = "Delivering"
    static Delivered: string = "Delivered"
    static Rejected: string = "Rejected" 
    static Pay: string = "Pay" 
    static Finalized: string = "Finalized" 
}