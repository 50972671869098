import _ from "lodash"
import Settings from "src/entities/Settings/Settings"
import { getSettings } from "src/services/settings"

const settingsReducer = async (state: Settings[] = [], action) => {

    switch (action.type) {
        case "INIT_SETTINGS":
            return [...await getSettings()]      

        default:
            return state
    }
}

export default settingsReducer