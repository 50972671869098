import _ from "lodash"
import { Order } from "src/entities/Order/Order"
import { getActiveSubOrder, newOrder } from "src/operations/orderOperations"

const order: Order = newOrder()
const orderReducer = (state: Order = order, action) => {
    switch (action.type) {

        case "REFRESH_ORDER_DATA":
            return { ...action.payload }

        case "CLEAR_ORDER":
            {
                const orderData: Order = state
                const activeSubOrder = getActiveSubOrder(orderData)
                activeSubOrder.items = []

                return { ...orderData, subOrders: [...orderData.subOrders.filter(x => x.id !== activeSubOrder.id), activeSubOrder] }
            }        

        default:
            return state
    }
}

export default orderReducer