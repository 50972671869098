// src/utils/localforageInstances.ts
import localforage from 'localforage'

// Function to create a new localforage instance with a specific storeName
export const createStorageInstance = (storeName: string) => {
    return localforage.createInstance({
        name: 'SoftBar', // Shared database name
        storeName,           // Unique storeName
        driver: localforage.INDEXEDDB // Default driver
    })
}

// Predefined instances for different data types
export const areaStorage = createStorageInstance('area')
export const paymentOptionsStorage = createStorageInstance('paymentOptions')
export const documentTypesStorage = createStorageInstance('documents')
export const customerGroupsStorage = createStorageInstance('customerGroup')
export const courierStorage = createStorageInstance('courier')
export const categoriesStorage = createStorageInstance('categories')
export const customersStorage = createStorageInstance('customers')
export const settingsStorage = createStorageInstance('settings')
export const deliveryAreasStorage = createStorageInstance('deliveryAreas')
export const ordersStorage = createStorageInstance('orders')
export const pendingOrdersStorage = createStorageInstance('pendingOrders')
export const pendingCustomerStorage = createStorageInstance('pendingCustomer')
export const waiterStorage = createStorageInstance('waiter')
