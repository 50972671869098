import orderReducer from "./order"
import orderItemWeightReducer from "./orderItemWeight"
import businessDataReducer from "./BusinessData"
import modifierReducer from "./modifiers"
import areaReducer from "./area"
import preloadReducer from "./preload"
import settingsReducer from "./settings"
import { combineReducers } from "redux"
import orderListReducer from "./orderList"

const allReducers = combineReducers({
    orderReducer,
    orderItemWeightReducer,
    businessDataReducer,
    modifierReducer,   
    areaReducer,
    preloadReducer,
    settingsReducer,
    orderListReducer
})

export default allReducers