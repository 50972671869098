import axios from "axios"
import { toast } from 'react-toastify'
import ErrorToast from "./components/alerts/toast"

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_POS_API_URL
})

function getCookie(cname) {
    const name = `${cname}=`
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ""
  }

axiosInstance.interceptors.request.use(config => {
    config.headers.Authorization =  `Bearer ${getCookie(process.env.REACT_APP_POS_COOKIE)}`
    config.headers.Channel =  process.env.REACT_APP_POS_CHANNEL
    
    return config
})


axiosInstance.interceptors.response.use(function (response) { 
  return response
}, function (error) {
  // console.log(error) 
  let message = ''
  if (error.response) {
    // console.log(error.response.data)
    // console.log(error.response.status)
    // console.log(error.response.headers)
   
    switch (error.response.status) {
          case 401:
            message = "You are not authorized. Please login and try again."
            break

          case 403:
            message = "You have no permission."
            break

          case 404:
            message = "Not found"
            break

          case 409:
            message = error.response.data.toString()
            break
    }
  }

  // if (message) toast.error(<ErrorToast message={message}/>)
  //return error
})


export default axiosInstance