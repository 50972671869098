import { Order } from "src/entities/Order/Order"
import { ordersStorage, pendingOrdersStorage } from "./localforageInstances"

export const saveOrderLocally = async (order: Order) => {
    await ordersStorage.setItem(order.info.references.id, order)
    await pendingOrdersStorage.setItem(order.info.references.id, order)
}

export const saveOrderOnlyLocally = async (order: Order) => {
    await ordersStorage.setItem(order.info.references.id, order)
}

export const savePreloadOrders = async (orders: Order[]) => {
    orders.forEach(async (order) => {
        const existsOrder = await ordersStorage.getItem(order.info.references.id)
        if (!existsOrder) await saveOrderOnlyLocally(order)
    })
}

export const getOrderLocally = async (orderId: string): Promise<Order | null> => {
    return await ordersStorage.getItem<Order>(orderId)
}

export const getOrdersLocally = async (): Promise<Order[]> => {
    const orders: Order[] = []
    await ordersStorage.iterate((value: Order, key: string) => {
        orders.push(value)
    })
    return orders
}
